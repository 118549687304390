import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { VariantHeading } from '../VariantSelector/VariantButtons';
import ProductLink from '@jetshop/ui/ProductLink';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { getColor } from './helpers';

const ButtonWrapper = styled('div')`
  > ul {
    display: flex;
    margin: 0 -1%;
    flex-wrap: wrap;
    > li {
      text-align: center;
      width: auto;
      margin: 0 5px 5px;
      > * {
        display: block;
      }
      .color {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #DDD;
        position: relative;
        &.no-color-code {
          border-color: #999;
          &:after {
            content: '';
            transform: rotate(45deg);
            position: absolute;
            height: 2px;
            top: calc(50% - 1px);
            width: 100%;
            background: #999;
            left: 0;
          }
        }
      }
      &.selected {
        .color {
          border-color: ${theme.colors.font};
        }
      }
    }
  }
  &.grid {
    overflow: hidden;
    position: relative;
    &:after {
      position: absolute;
      right: 0;
      width: 50px;
      top: 0;
      content: '';
      background: linear-gradient(
        to right,
        rgba(248, 245, 239, 0) 0%,
        rgba(248, 245, 239, 1) 90%,
        rgba(248, 245, 239, 1) 100%
      );
      height: 100%;
    }
    > ul {
      flex-wrap: nowrap;
      margin: 0 -2px;
      > li {
        margin: 0 2px;
        .color {
          width: 12px;
          height: 12px;
          border-width: 1px;
          &.no-color-code {
            &:after {
              height: 1px;
              top: 3.6px;
            }
        }
        &.selected {
          .color:not(.no-color-code) {
            border-color: ${theme.colors.font};
          }
        }
      }
    }
  }
`;

export const SiblingButtons = ({ productList, mainProduct, grid = false }) => {
  const { otherColorsTitle, colorList } = useContext(SiteContentContext);
  return (
    <ButtonWrapper className={`sibling-button-wrapper ${grid ? 'grid' : ''}`}>
      {!grid && <VariantHeading>{otherColorsTitle}</VariantHeading>}
      <ul>
        {productList.map((product) => {
          const color = getColor(product);
          const currentProduct = product.id === mainProduct.id;
          const Tag = currentProduct ? 'div' : ProductLink;
          const colorCode = colorList && colorList[color];
          return (
            <li key={product.id} className={currentProduct ? 'selected' : ''}>
              <Tag product={product}>
                <span
                  className={`color ${colorCode ? '' : 'no-color-code'}`}
                  data-color={color}
                  style={{
                    backgroundColor: '#' + colorCode,
                  }}
                />
              </Tag>
            </li>
          );
        })}
      </ul>
    </ButtonWrapper>
  );
};
