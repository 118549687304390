import { useCookies } from 'react-cookie';

export function useChannelBanner() {
  // Set display state for recommended channel banner
  const [cookies, setCookie] = useCookies(['hideBanner']);

  // If we have a hideBanner cookie, we want to hide the banner! So we will use
  // this as the initial state for bannerIsOpen
  let hideBanner = true;
  const cookie = cookies.hideBanner;
  if (cookie === undefined) {
    hideBanner = false;
  }

  // If user interacts with channel banner, set cookie to hide it permanently
  function hideTheBanner() {
    setCookie('hideBanner', true, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365 * 10
    });
  }

  return { 
    hideBanner,
    hideTheBanner
  };
}
