import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { Color } from './Color';
import ProductLink from '@jetshop/ui/ProductLink';
import PlusIcon from '../../ui/icons/PlusIcon';
import SiteContentContext from '../../SiteContent/SiteContentContext';

//STYLED COMPONENTS
const ColorCircleWrapper = styled('div')`
  position: relative;
`;

const ColorCirclesWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  height: 15px;
  position: relative;
  margin-top: 4px;
  ${ColorCircleWrapper} {
    margin: 0 2px 2px;
  }
`;

const MoreColors = styled('div')`
  position: relative;
  svg {
    color: #aaa;
  }
  > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    padding: 2px 3px;
  }
`;

//HELPERS
export function colorName(name) {
  return (
    name.toLowerCase() === 'farge' ||
    name.toLowerCase() === 'färg' ||
    name.toLowerCase() === 'color' ||
    name.toLowerCase() === 'colour'
  );
}

export function checkForColor(product) {
  if (!product.hasVariants) return false;
  const option =
    product.variants &&
    product.variants.options.filter((option) => {
      return colorName(option.name);
    });
  if (option && option.length > 0) return option[0];
  else return false;
}

//EVENT HANDLER
function handleEvent(setVariant, value, option) {
  const secondOption =
    setVariant.missingOptions?.length === 2 &&
    setVariant.missingOptions.filter((opt) => !colorName(opt.name))[0];
  if (setVariant) {
    setVariant.selectValue(value, option);
    if (secondOption)
      setVariant.selectValue(secondOption.values[0], secondOption);
  }
}

export function splitColors(colors) {
  const colorList = colors.split('\n');
  const colorObject = {};
  colorList.forEach((item) => {
    const data = item.split('#');
    colorObject[data[0].toLowerCase()] = data[1];
  });
  return colorObject;
}

export function getColor(colorObject, value) {
  const lowerValue = value.toLowerCase();
  if (typeof colorObject[lowerValue] !== 'undefined')
    return colorObject[lowerValue];
  return 'ff0092';
}

//CIRCLE COMPONENT
const ColorCircle = ({ value, setVariant, option, colorObject, ...rest }) => {
  const selected = setVariant && setVariant.getSelectedValue(option);
  const color = getColor(colorObject, value);
  return (
    <ColorCircleWrapper
      onMouseEnter={() => handleEvent(setVariant, value, option)}
      onClick={() => handleEvent(setVariant, value, option)}
      {...rest}
    >
      <Color className={selected === value ? 'selected' : null} color={color} />
    </ColorCircleWrapper>
  );
};

//ALL CIRCLES COMPONENT
export const ColorCircles = ({ product, setVariant = false }) => {
  const colorOption = checkForColor(product);
  const colorAmount = 7;

  //SHOW ONLY FIRST {colorAmount} COLORS
  const moreColors =
    colorOption.values && colorOption.values.length > colorAmount;

  const { colorList } = useContext(SiteContentContext);
  if (!colorList) return false;

  if (colorOption) {
    const colorObject = splitColors(colorList);
    return (
      <ColorCirclesWrapper>
        {colorOption.values.map((value, index) => {
          if (index < colorAmount)
            return (
              <ColorCircle
                colorObject={colorObject}
                key={index}
                value={value}
                setVariant={setVariant}
                option={colorOption}
              />
            );
          return null;
        })}
        {moreColors && (
          <MoreColors>
            <ProductLink product={product}>
              <PlusIcon />
            </ProductLink>
          </MoreColors>
        )}
      </ColorCirclesWrapper>
    );
  } else return <ColorCirclesWrapper />;
};
