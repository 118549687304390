import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import cartQuery from './CartQuery.gql';
import { styled } from 'linaria/react';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import CartIcon from '../ui/icons/CartIcon';
import { HeaderIconText } from '../Layout/Header/Desktop/DesktopHeader';
import t from '@jetshop/intl';
import { NumberBadge } from '../ui/NumberBadge';

const Button = styled('button')`
  display: flex;
  flex-direction: column;
  align-items: center;
  .cart-items-text {
    margin-left: 2px;
  }
`;

/*const CartLabel = styled('span')`
  font-size: 16px;
  color: ${theme('colors.font')};
  margin-top: 5px;
  margin-left: 10px;
  text-transform: uppercase;
`;*/

/*const FlyoutTriggerWrapper = ({ itemsInCart }) => (
  <FlyoutTrigger id="cart-flyout">
    {flyout => (
      <Button onClick={flyout.isOpen ? flyout.hideTarget : flyout.showTarget}>
        <StyledIcon icon={shoppingBag} size={28} />
        {parseInt(itemsInCart, 10) > 0 && <CartItems>{itemsInCart}</CartItems>}
      </Button>
    )}
  </FlyoutTrigger>
);*/

const DrawerTriggerWrapper = ({ itemsInCart, children, className }) => (
  <DrawerTrigger
    preventOverflow={true}
    id="cart-drawer"
    coverStyles={{ zIndex: 104 }}
  >
    {(drawer) => (
      <Button
        data-testid="cart-button"
        onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
        className={className}
      >
        <CartIcon className="header-icon" />
        {parseInt(itemsInCart, 10) > 0 && <NumberBadge text={itemsInCart} />}
        <HeaderIconText>
          <span>{t('Cart')}</span>
          {parseInt(itemsInCart, 10) > 0 && (
            <span className="cart-items-text">({itemsInCart})</span>
          )}
        </HeaderIconText>
        {children}
      </Button>
    )}
  </DrawerTrigger>
);

const CartButton = ({ children, className }) => (
  <Fragment>
    <CartProvider query={cartQuery}>
      {(result) => {
        // Set items in cart to the API result.
        // If the result is undefined, fall back to 0
        const itemsInCart = result?.data?.cart?.totalQuantity || 0;
        return (
          <DrawerTriggerWrapper
            itemsInCart={itemsInCart}
            children={children}
            className={className}
          />
        );
      }}
    </CartProvider>
  </Fragment>
);

export default CartButton;
