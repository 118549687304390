import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const TopBarUspWrapper = styled('div')`
  > ul {
    > li {
      display: flex;
      align-items: center;
      font-size: ${theme.topBar.usp.fontSize};
      font-weight: ${theme.topBar.usp.fontWeight} !important;
      ${theme.only.xs} {
        font-size: 0.85rem;
      }
      svg {
        margin-right: 5px;
      }
      ${theme.only.xs} {
        &:first-child {
          padding-left: 0 !important;
        }
        &:last-child {
          padding-right: 0 !important;
        }
      }
      ${theme.below.sm} {
        &:nth-of-type(3),
        &:nth-of-type(4) {
          display: none;
        }
      }
      ${theme.below.md} {
        &:nth-of-type(4) {
          display: none;
        }
      }
    }
  }
`;

const TopBarUsp = ({ data }) => {
  const { topBarUsp } = useContext(SiteContentContext);
  const outputData = data ? data : topBarUsp;
  if (outputData)
    return (
      <TopBarUspWrapper className="top-bar-usp-list">
        {outputData}
      </TopBarUspWrapper>
    );
  return null;
};

export default TopBarUsp;
