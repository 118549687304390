import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';

export const ProductListHeader = styled('h2')`
  padding: 1rem 0 2rem;
  width: 100%;
`;

export const ProductListSubHeader = styled('h4')`
  padding: 0 0;
  width: 100%;
  font-family: ${theme.typography.fontFamily} !important;
  font-weight: ${theme.weights.bold} !important;
`;

export const ProductInfoMarginBottom = `
  margin-bottom: 0.6rem;
  ${theme.above.md} {
    margin-bottom: 1rem;
  }
`;
