import React from 'react';
import { styled } from 'linaria/react';
import { withRouter } from 'react-router-dom';
import { ProductInfoMarginBottom } from '../StyledComponents';
import { SiblingButtons } from './SiblingButtons';
import { SiblingProvider } from './SiblingProvider';

const Wrapper = styled('div')`
  width: 100%;
  ${ProductInfoMarginBottom};
`;

export const siblingfield = 'other-colors';

const SiblingProducts = ({ product, grid }) => {
  return (
    <SiblingProvider product={product}>
      {({ productList }) => {
        if (productList.length > 1)
          return (
            <Wrapper className={`sibling-products-wrapper`}>
              <SiblingButtons
                productList={productList}
                mainProduct={product}
                grid={grid}
              />
            </Wrapper>
          );
        else return <Wrapper className={`sibling-products-wrapper`} />;
      }}
    </SiblingProvider>
  );
};

export default withRouter(SiblingProducts);
