import { siblingfield } from './SiblingProducts';

export function getId(product) {
  return (
    product &&
    product.name.trim().substr(0, product.name.trim().lastIndexOf(' '))
  );
}
export function getData(data) {
  const item = data.filter((item) => item.key === siblingfield)[0];
  return item && item.listValues;
}
export function getList(data, pos = 0) {
  return data.map((item) => {
    const data = item.split(',');
    if (data.length > pos) return data[pos];
    else return null;
  });
}
export function getColor(product) {
  return product?.customFields
    ?.filter((item) => item.key === 'color')[0]
    ?.stringValue.toLowerCase();
}
export function filterList(products, term) {
  let list = products.filter((product) => {
    if (getId(product) === term) return product;
    else return null;
  });

  return list.sort((a, b) => (a.articleNumber > b.articleNumber ? 1 : -1));
}

export function filterOutMainProduct(products, mainProduct) {
  return products.filter((product) => {
    return product.id !== mainProduct.id;
  });
}

export function activate(product) {
  if (
    product &&
    product.variants &&
    product.variants.options &&
    product.variants.options.length
  ) {
    if (product.variants.options[0].name.toLowerCase().search('storlek') !== -1)
      return false;
  }
  return true;
}

export function hasColorAttribute(product) {
  if (
    product &&
    product.variants &&
    product.variants.options &&
    product.variants.options.length
  ) {
    if (product.variants.options[0].name.toLowerCase().search('färg') !== -1)
      return true;
  }
  return false;
}
