import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import cartQuery from '../../Cart/CartQuery.gql';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../Theming/Theming';
import React from 'react';
import { styled } from 'linaria/react';
import { ListPrice } from '../../CategoryPage/ProductCard';
import { ButtonUI } from '../../ui/ButtonUI';

const Container = styled('aside')`
  ${theme.above.sm} {
    width: 360px;
  }
  ${theme.below.sm} {
    width: 300px;
  }
  background-color: ${theme.colors.white};
  line-height: 1em;
`;

const CheckoutButton = styled(ButtonUI)``;

const ProductImageWrapper = styled('div')`
  width: 5rem;
  margin-right: 1rem;
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Product = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 1rem;
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;
  [data-flight-price] {
    justify-content: flex-start !important;
  }
`;

const Header = styled('h4')`
  margin-bottom: 0.5rem;
`;

const ProductName = styled('p')`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`;

const VariantText = styled('span')`
  &:before {
    content: '-';
    margin: 0 3px;
  }
`;

const Error = styled('p')`
  color: red;
  margin-top: 1rem;
`;

const ProductToast = ({
  product,
  cart,
  closeToast,
  selectedVariation,
  quantity,
  showPrice = true,
  error,
}) => {
  const selected = selectedVariation || product;
  const track = useTracker();
  const image = selectedVariation?.images[0] || product.images[0];
  return (
    <Container>
      <ProductCheckoutContainer>
        <Product>
          {image && (
            <ProductImageWrapper>
              <Image src={image.url} sizes={100} aspect="1:1" alt={image.alt} />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            {error ? (
              <Header>{t('Failed adding to bag')}</Header>
            ) : (
              <Header>{t('Added to bag')}</Header>
            )}
            <ProductName>
              {product.name}
              {selected.values && selected.values.length > 0 && (
                <VariantText>{selected.values[0]}</VariantText>
              )}
              {selected.values && selected.values.length > 1 && (
                <VariantText>{selected.values[1]}</VariantText>
              )}
            </ProductName>

            {!error && showPrice && (
              <ListPrice
                price={selected.price}
                recommendedPrice={selected.recommendedPrice}
                previousPrice={selected.previousPrice}
              />
            )}
            {error && (
              <Error>
                {t('An error occurred. Details:')}
                <ul>
                  {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                    error.graphQLErrors.map(({ message, locations, path }) => (
                      <li key={message}>{t(message)}</li>
                    ))
                  ) : (
                    <li>{t(error.message)}</li>
                  )}
                </ul>
              </Error>
            )}
          </ProductDetails>
        </Product>
        {cart && cart.externalCheckoutUrl && (
          <ChannelContext.Consumer>
            {({ selectedChannel }) => (
              <CheckoutButton className="buy">
                <a
                  href={`${cart.externalCheckoutUrl}&channelCountry=${selectedChannel.country.code}`}
                  onClick={(event) => {
                    event.preventDefault();
                    track(
                      trackCartCheckoutEvent({
                        cart: cart,
                        callback: () => {
                          window.location = cart.externalCheckoutUrl;
                        },
                      })
                    );
                  }}
                >
                  {t('To checkout')}
                </a>
              </CheckoutButton>
            )}
          </ChannelContext.Consumer>
        )}
      </ProductCheckoutContainer>
    </Container>
  );
};
const ProductToastWrapper = (props) => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
