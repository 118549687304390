import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { LoginWidget } from '../Util/LoginWidget';
import SiteContentContext from '../../../SiteContent/SiteContentContext';
import { theme } from '../../../Theming/Theming';

const TopBarLinksWrapper = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  > ul {
    > li {
      a {
        font-weight: ${theme.weights.bold} !important;
        text-decoration: underline;
      }
    }
  }
`;

const TopBarLinks = () => {
  const { topBarLinks } = useContext(SiteContentContext);
  return (
    <TopBarLinksWrapper className="top-bar-links-list">
      {topBarLinks}
      <LoginWidget />
    </TopBarLinksWrapper>
  );
};

export default TopBarLinks;
