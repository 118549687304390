import React, { useContext } from 'react';
import SiteContentContext from './SiteContentContext';
import { SiteContentHtmlParser } from './SiteContentHtmlParser';
import { useQuery } from 'react-apollo';
import siteContentQuery from './siteContentQuery.gql';
import { config } from '../../shop.config.custom';
import B2BContext from '../Util/B2BContext';

export const convertPropsToSiteContentObject = (item) => {
  const props = item.properties.reduce((merged, current) => {
    const propValue = current.value && current.value.value;
    merged[current.name] =
      current.type === 'html' ? (
        <SiteContentHtmlParser content={propValue} />
      ) : current.type === 'dropdown' && current.name.search('show') !== -1 ? (
        propValue === 'yes' ? (
          true
        ) : (
          false
        )
      ) : current.name === 'colorList' && propValue ? (
        propValue.split('\n').reduce((merged, current) => {
          const data = current.split('#');
          merged[data[0].toLowerCase()] = data[1];
          return merged;
        }, {})
      ) : (
        propValue
      );
    return merged;
  }, {});
  return props;
};

const SiteContent = ({ children }) => {
  const { isB2B } = useContext(B2BContext);
  const result = useQuery(siteContentQuery, {
    variables: {
      id: isB2B
        ? config.settings.siteContentB2BId
        : config.settings.siteContentId,
    },
  });

  const startPage = result?.data?.startPage;
  const items = startPage?.data?.items;

  const siteContentObject =
    items && items.filter((item) => item.type === 'site-content')[0];

  const props =
    siteContentObject && convertPropsToSiteContentObject(siteContentObject);

  return (
    <SiteContentContext.Provider value={{ ...props }}>
      {children}
    </SiteContentContext.Provider>
  );
};

export default SiteContent;
