import React from 'react';
import { styled } from 'linaria/react';
import ChannelSelector, {
  LangCurrWrapper,
  SelectorBody
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import {
  CancelButton,
  CommitButton
} from '@jetshop/ui/ChannelSelector/ChannelSelectorButtons';
import LargeSelector from '@jetshop/ui/ChannelSelector/LargeSelector';
import CheckboxGroup from '@jetshop/ui/Checkbox/CheckboxGroup';

const Wrapper = styled('div')`
  & > div:first-child {
    border: 1px solid #e1e1e1;
    background: #ffffff;
  }
`;

const Country = styled('div')`
  height: 42px;
  font-size: 14px;
  background-color: ${({ isActive }) => (isActive ? '#ebebeb' : 'transparent')};
  padding: 0 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  color: ${({ isActive }) => (isActive ? '#000' : '#4f4f4f')};
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  opacity: ${({ isActive }) => (isActive ? '1' : '0.75')};
  :hover {
    opacity: 1;
  }
`;

const CountryFlag = styled('img')`
  height: 22px;
  width: 22px;
  margin-right: 15px;
`;

const StylesWrapper = styled('div')`
  label {
    display: inline-flex;
  }

  ${LangCurrWrapper} {
    background: #f9f9f9;
    margin-bottom: 0;
  }
`;

const ChecboxGroupContainer = styled('div')`
  & > div {
    position: relative;
    padding-left: 80px;
    & > span {
      position: absolute;
      top: 0;
      left: 0;
      color: #000;
      & ~ label {
        margin-top: 5px;
        color: #000;
      }
      & + label {
        margin-top: 0;
      }
    }
    &:nth-child(2) {
      margin-top: 20px;
    }
  }
  input ~ span {
    border: 1px solid #333;
    svg {
      color: #333;
      height: 8px;
      path {
        fill: #333;
      }
    }
  }
  label input:checked ~ span {
    color: #333;
    background-color: #333;
  }
  .currency-selector {
    margin-top: 10px;
  }
`;

const StyledChannelSelector = styled(ChannelSelector)`
  border: 1px solid #e1e1e1;
`;

const StyledSelectorBody = styled(SelectorBody)`
  padding: 0;
`;

const buttonCss = `
  margin: 0;
  width: 100%;
`;

const StyledCommitButton = styled(CommitButton)`
  ${buttonCss};
  background: #000000;
`;

const StyledCancelButton = styled(CancelButton)`
  ${buttonCss};
  border: 1px solid #e1e1e1;
`;

const Selector = ({
  channels,
  selectedChannel,
  updateChannel,
  setOpenBanner,
  hideTarget
}) => {
  //Filter.
  const b2cChannels = channels.filter(function(channel) {
    return channel.settings.pricesIncVat === true;
  })
  //Reorder.
  const intIndex = b2cChannels.findIndex(channel => channel.name === 'International');
  if (intIndex !== -1) {
    const intChannel = b2cChannels.splice(intIndex, 1)[0];
    b2cChannels.push(intChannel);
  }
  let code; 

  return (
    <StyledChannelSelector
      channels={b2cChannels}
      initialChannel={selectedChannel}
      render={({
        channels,
        currencyItems,
        languageItems,
        selectedChannel,
        onSelect,
        hasChanged,
        onCurrencySelect,
        onLanguageSelect
      }) => {
        languageItems.forEach((lang) => {
          if (lang.label === 'Engelska') {
            lang.label = 'English';
          } else if (lang.label === 'Svenska') {
            lang.label = 'Swedish';
          } else if (lang.label === 'Danska') {
            lang.label = 'Danish';
          }
        })
        return (
        <Wrapper>
          <LargeSelector>
            <StyledSelectorBody>
              {b2cChannels.map(({ name, id }) => {
                name === 'Sweden' ? code = 'SE' : (name === 'Denmark' ? code = 'DK' : code = 'EU');
                return (
                <Country
                  key={id}
                  isActive={
                    id === selectedChannel.id &&
                    name === selectedChannel.country.name
                  }
                  onClick={() => onSelect(id, null, null, code)}
                >
                  <CountryFlag
                    src={`https://countryflags.jetshop.io/${code}/flat/32.png`}
                    alt="Country Flag"
                  />
                  {name}
                </Country>
                )
              })}
            </StyledSelectorBody>
            <StylesWrapper>
              <LangCurrWrapper>
                <ChecboxGroupContainer>
                  <CheckboxGroup 
                    selectedItem={selectedChannel.language.name}
                    items={languageItems}
                    handleChange={onLanguageSelect}
                    groupLabel='Language'
                  />
                  <CheckboxGroup
                    selectedItem={selectedChannel.currency.name}
                    items={currencyItems}
                    handleChange={onCurrencySelect}
                    groupLabel='Currency'
                  />
                </ChecboxGroupContainer>
              </LangCurrWrapper>
            </StylesWrapper>

            {hasChanged ? (
              <StyledCommitButton
                onClick={() => {
                  updateChannel(selectedChannel);
                  hideTarget();
                }}
                text='Update'
              />
            ) : (
              <StyledCancelButton
                text='Close'
                onClick={() => hideTarget()}
              />
            )}
          </LargeSelector>
        </Wrapper>
      )}}
    />
  );
};

export default Selector;
