export const config = {
  routes: {
    blog: {
      path: '/inspiration',
      id: 418,
      mainLevel: 1,
      menu: true,
    },
    stores: {
      path: '/stores',
    },
    brand: {
      id: 154,
    },
    category: {
      hide: false,
    },
    product: {
      hide: false,
    },
  },
  addwish: {
    categoryPage: 'k5f71d6ec253f5f493c5f49f4',
    productPage: {
      alternatives: 'k5df754d66be16c6eb18be333',
      others: 'k5df754cf6be16c6eb18be1dd',
    },
    notFound: {
      retarget: 'k5df754e66be16c6eb18be63a',
      top: 'k5df754ea6be16c6eb18be6cb',
    },
    cart: 'k5df754fb6be16c6eb18be9d7',
  },
  settings: {
    storeName: 'Jumperfabriken',
    siteContentId: 170,
    siteContentB2BId: 171,
  },
};
