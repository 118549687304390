import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { styled } from 'linaria/react';
import { GridPrice } from '../Price';
import t from '@jetshop/intl';
import { theme } from '../Theming/Theming';
import SiblingProducts from '../ProductPage/SiblingProducts/SiblingProducts';
import { Above } from '@jetshop/ui/Breakpoints';

export const ProductCardInner = styled('div')`
  padding: ${theme.productGrid.product.padding.sm};
  background-color: ${theme.productGrid.product.background};
  border: ${theme.productGrid.product.border};
  position: relative;
  line-height: 1;
  min-width: 0;
  ${theme.above.md} {
    padding: ${theme.productGrid.product.padding.md};
  }
  ${theme.above.xl} {
    padding: ${theme.productGrid.product.padding.lg};
  }
  > a {
    color: inherit;
    display: block;
  }
  > button {
    width: 100%;
  }
`;

export const Details = styled('div')`
  margin: 0.7rem 0 0.5rem;
  padding: 0 0.5rem;
  position: relative;
`;

export const DetailsInner = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-end;
  > * {
    flex-grow: 0;
    width: 100%;
  }
  header {
    margin-bottom: 1rem;
  }
  .sibling-products-wrapper {
    margin: 1rem 0 0;
    height: 12px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  background: ${theme.colors.white};

  .firstImage, .secondImage {
    transition: opacity 0.6s;
  }

  .firstImage {
    z-index: 2;
  }
  
  .secondImage {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0; 
  }

  &:hover {
    &.hoverable {
      .firstImage {
        opacity: 0;
      }
      .secondImage {
        opacity: 1;
      }
    }
  }
`;

export const BadgeWrapper = styled('div')`
  color: white;
  text-align: center;
  font-size: 0.8rem;
  img {
    width: auto;
    height: auto;
    max-width: 70px;
    ${theme.below.md} {
      max-width: 50px;
    }
  }
`;

export const Name = styled('h4')`
  margin: 0 0 0 0 !important;
  padding: 0;
  padding-right: 0.8rem;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.1;
  font-family: ${theme.productGrid.product.name.fontFamily} !important;
  font-weight: ${theme.productGrid.product.name.fontWeight} !important;
  text-transform: none !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const SubName = styled('p')`
  margin: 0 0 0.7rem 0;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${theme.below.md} {
    font-size: 0.9rem;
  }
`;
export const ListPrice = styled(GridPrice)``;

const PackagePrice = styled('div')`
  font-size: 0.8rem;
  margin-top: 5px;
`;

const ProductCardComponent = ({
  product,
  imageAspect,
  imageSizes,
  children,
  showFavorite,
  categoryPath,
  ...rest
}) => {
  //HAS IMAGES
  const hasImages = product.images && product.images.length > 0;

  //STANDARD PRODUCT IMAGE
  const images = product && product.images;
  const isHoverable = images && images.length > 1;

  let badges = [...product.badges];

  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge',
    });
  return (
    <ProductCardInner className="product-card-inner">
      <ProductLink product={product} categoryPath={categoryPath} {...rest}>
        {hasImages ? (
          <Above breakpoint="lg">
            {(matches) =>
              matches && isHoverable ? (
              <ImageWrapper
                className={isHoverable ? 'hoverable' : ''}
              >
                <Image
                  className='firstImage'
                  crop={true}
                  cover={true}
                  sizes={imageSizes}
                  aspect={imageAspect}
                  alt={images[0].alt}
                  src={images[0].url}
                  modifiedDate={images[0].modifiedDate}
                />            
                <Image
                  className='secondImage'
                  crop={true}
                  cover={true}
                  sizes={imageSizes}
                  aspect={imageAspect}
                  alt={images[0].alt}
                  src={images[1].url}
                  modifiedDate={images[0].modifiedDate}
                />  
                <BadgeWrapper>
                    <Badges badges={product.badges} />
                </BadgeWrapper>
              </ImageWrapper>
            ) : (
              <ImageWrapper>
                <Image
                  className='firstImage'
                  crop={true}
                  cover={true}
                  sizes={imageSizes}
                  aspect={imageAspect}
                  alt={images[0].alt}
                  src={images[0].url}
                  modifiedDate={images[0].modifiedDate}
                />            
                <BadgeWrapper>
                  <Badges badges={product.badges} />
                </BadgeWrapper>
              </ImageWrapper>
            )}
          </Above>
        ) : (
          <Image src={transparentDataImg} aspect={imageAspect} />
        )}
      </ProductLink>
      <Details className="product-card-detail">
        <DetailsInner>
          <header>
            <div>
              <ProductLink product={product} categoryPath={categoryPath}>
                <Name>{product.name}</Name>
              </ProductLink>
            </div>
          </header>
          {product.isPackage && (
            <PackagePrice className="package-price-label">
              {t('Package Price')}
            </PackagePrice>
          )}
          <ListPrice
            price={product.price}
            previousPrice={product.previousPrice}
          />
          <SiblingProducts product={product} grid={true} />
        </DetailsInner>
      </Details>
      {children}
    </ProductCardInner>
  );
};

export function ProductCard({
  product,
  className,
  imageAspect = theme.productGrid.product.imageAspect,
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'div',
  children,
  showFavorite = true,
  showVariants = true,
  ...linkProps
}) {
  const Tag = as;

  //PROPS TO PASS TO PRODUCTCARD COMP
  const props = {
    imageAspect,
    imageSizes,
    children,
    showFavorite,
    ...linkProps,
  };

  return (
    <Tag
      className={`product-card ${className}`}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductCardComponent product={product} {...props} />
    </Tag>
  );
}
