import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import TypekitFont from './Util/Fonts/TypekitFont';
import CustomFont from './Util/Fonts/CustomFont';
//import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
//import TypekitFont from '@jetshop/core/components/Fonts/TypekitFont';
import loadFontCss from '../fonts/loadFontCss';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import routeQuery from './RouteQuery.gql';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import './Theming/globalStyles';
import { config } from '../shop.config.custom';
import { theme } from './Theming/Theming';
import CookieConsent from './Util/CookieConsent';
import SiteContent from './SiteContent/SiteContentProvider';
import NotFoundPage from './NotFoundPage.loadable';
import LogInPage from './Auth/LogInPage.loadable';
import StartPage from './StartPage/StartPage.loadable';
import ProductPage from './ProductPage/ProductPage.loadable';
import CategoryPage from './CategoryPage/CategoryPage.loadable';
import ContentPage from './ContentPage/ContentPage.loadable';
import SearchPage from './SearchPage/SearchPage.loadable';
import SignUpPage from './Auth/Signup/SignUpPage.loadable';
import ForgotPassword from './Auth/ForgotPassword.loadable';
import ResetPassword from './Auth/ResetPassword.loadable';
import MyPages from './MyPages/MyPages.loadable';
import Stores from './Stores/Stores.loadable';
import BlogPage from './Blog/BlogPage.loadable';
import BlogArticle from './Blog/BlogArticle.loadable';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import B2BContext from './Util/B2BContext';
import ChannelBanner from './Layout/Header/RecommendedChannel/ChannelBanner';

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />,
  }
);
const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />,
  }
);

function Shop() {
  const { routes } = useShopConfig();
  const { selectedChannel } = useContext(ChannelContext);

  //HANDLING B2B STATES
  const isB2B = selectedChannel.id === 3 || selectedChannel.id === 4;

  return (
    <GenericError>
      <ModalProvider>
        <B2BContext.Provider
          value={{
            isB2B,
            showVariantGrid: isB2B,
          }}
        >
          <SiteContent>
            <Container>
              <LoadingBar />
              <ChannelBanner/>
              <CustomFont
                secondaryFonts={[theme.font.secondary]}
                injectCss={loadFontCss}
              />
              <TypekitFont primaryFont={theme.font.primary} id="lrl4but" />
              <Helmet
                titleTemplate={`%s`}
                defaultTitle={config.settings.storeName}
              />
              <Header />
              <Content>
                <PaginationProvider defaultProductsPerPage={36}>
                  <Switch>
                    <Route exact path={`${config.routes.blog.path}/:cat/:item`}>
                      <BlogArticle configObject={config.routes.blog} />
                    </Route>
                    <Route exact path={`${config.routes.blog.path}/:cat`}>
                      <BlogPage configObject={config.routes.blog} />
                    </Route>
                    <Route exact path={config.routes.blog.path}>
                      <BlogPage configObject={config.routes.blog} />
                    </Route>
                    <Route exact path="/">
                      <StartPage />
                    </Route>
                    <Route path={routes.search.path}>
                      <SearchPage />
                    </Route>
                    <Route path={routes.signup.path}>
                      <SignUpPage />
                    </Route>
                    <Route path={routes.login.path}>
                      <LogInPage />
                    </Route>
                    <Route path={routes.logout.path}>
                      <LogOutPage />
                    </Route>
                    <Route path={config.routes.stores.path}>
                      <Stores />
                    </Route>
                    <Route path={routes.myPages.path}>
                      <MyPages />
                    </Route>
                    <Route exact path={routes.forgotPassword.path}>
                      <ForgotPassword />
                    </Route>
                    <Route path={`${routes.resetPassword.path}/:token`}>
                      <ResetPassword />
                    </Route>
                    <Route
                      path="/preview"
                      render={(props) => (
                        <PreviewRoute
                          productQuery={ProductPreviewQuery}
                          categoryQuery={CategoryPreviewQuery}
                          pageQuery={PagePreviewQuery}
                          productPage={ProductPage}
                          categoryPage={CategoryPage}
                          contentPage={ContentPage}
                          StartPage={StartPage}
                          {...props}
                        />
                      )}
                    />
                    <DynamicRoute
                      routeQuery={routeQuery}
                      productPage={ProductPage}
                      categoryPage={CategoryPage}
                      contentPage={ContentPage}
                      notFoundPage={NotFoundPage}
                      LoadingPage={LoadingPage}
                    />
                  </Switch>
                </PaginationProvider>
              </Content>
              <Footer />
              <ModalRoot />
              <ScrollRestorationHandler
                ignoreForRouteTypes={[
                  'sortOrderChange',
                  'filterChange',
                  'paginationChange',
                ]}
              />
              <CookieConsent />
            </Container>
          </SiteContent>
        </B2BContext.Provider>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
