import React from 'react';
import { Query } from 'react-apollo';
import siblingsQuery from './SiblingsQuery.gql';
import { getId, filterList } from './helpers';

export const SiblingProvider = ({ product, children }) => {
  const term = getId(product);
  if (term)
    return (
      <Query variables={{ term: term }} query={siblingsQuery}>
        {(result) => {
          const products =
            (result &&
              result.data &&
              result.data.search &&
              result.data.search.products.result) ||
            [];
          const productList = filterList(products, term);
          return children({ productList });
        }}
      </Query>
    );
  else return null;
};
