import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { ProductInfoMarginBottom } from '../StyledComponents';
import {
  colorName,
  getColor,
} from '../../CategoryPage/ColorCircles/ColorCircles';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { Color } from '../../CategoryPage/ColorCircles/Color';

const StyledButton = styled('button')`
  background: transparent;
  border: 1px solid ${theme.colors.font};
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  //width: 31.333333%;
  padding: 0.5rem 1rem;
  margin: 0 1% 2%;
  font-size: 0.9rem;
  position: relative;
  ${theme.above.md} {
    //width: 23%;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    margin: 0 1% 2%;
  }
  &:after {
    content: ' ';
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: -9px;
    left: 0;
  }
  &[data-selected='true'] {
    &:after {
      background: ${theme.colors.font};
    }
  }
  &[data-disabled='true'] {
    cursor: not-allowed;
  }
  &.not-buyable,
  &[data-disabled='true'] {
    border-color: #eee;
    background: ${theme.colors.greys[0]};
    color: #aaa;
    text-decoration: line-through;
    &[data-selected='true'] {
      border-color: #777;
    }
  }
  &.color {
    padding: 0;
    .color-circle {
      > div {
        width: 36px;
        height: 36px;
        border-radius: 0;
        border: none;
      }
    }
  }
`;
const VariantButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -1% 0;
  ${ProductInfoMarginBottom}
  &.invalid button {
    box-shadow: 0 0 10px rgba(195, 45, 45, 0.38);
  }
`;

export const VariantHeading = styled('p')`
  margin-bottom: 0.3rem;
  font-weight: ${theme.weights.bold};
  text-transform: uppercase;
  .seperator {
    margin: 0 4px 0 2px;
  }
`;

const VariantWrapper = styled('div')`
  width: 100%;
`;

const SizeGuideButton = styled('button')`
  text-transform: inherit;
`;

const VariantButton = ({
  option,
  variantHandler,
  showValidationMessage,
  sizeGuide = false,
}) => {
  const {
    getSelectedValue,
    validateSelection,
    selectValue,
    getVariantForSelection,
  } = variantHandler;
  const selectedValue = getSelectedValue(option);

  const { colorList } = useContext(SiteContentContext);
  const isColor = colorList && colorName(option.name);

  return (
    <VariantWrapper>
      <VariantHeading>
        {option.name}{' '}
        {sizeGuide && (
          <>
            <span className="seperator">|</span>
            <SizeGuideButton
              onClick={() => {
                //SCROLL TO TABS
                if (typeof document !== 'undefined') {
                  const tab = document.getElementById(
                    'drop-header-tab-page-sizeguide'
                  );
                  if (tab) {
                    const yOffset = -100;
                    const y =
                      tab.getBoundingClientRect().top +
                      window.pageYOffset +
                      yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                  }
                }
              }}
            >
              {sizeGuide}
            </SizeGuideButton>
          </>
        )}
      </VariantHeading>
      <VariantButtonWrapper className={showValidationMessage && 'invalid'}>
        {option.values.map((value) => {
          const validation = validateSelection(value, option);
          const item = getVariantForSelection(value, option);
          const color = isColor && colorList && getColor(colorList, value);
          return (
            <StyledButton
              key={option.name + value}
              onClick={() => selectValue(value, option)}
              data-disabled={validation === 'invalid'}
              data-secondary={value !== selectedValue}
              data-selected={value === selectedValue}
              className={`${
                item && item.stockStatus && !item.stockStatus.buyable
                  ? 'not-buyable '
                  : ''
              }${isColor ? 'color ' : ''}`}
            >
              {isColor ? <Color color={color} /> : <span>{value}</span>}
            </StyledButton>
          );
        })}
      </VariantButtonWrapper>
    </VariantWrapper>
  );
};

export default VariantButton;
